<template>
  <div class="GalleryImage" :file="`file${item._id}`">
    <img width="100%" :src="item.file.src" loading="lazy" alt="Image" @load="loaded" />
    <a class="OptionsButton" v-if="$isAdmin" @click="itemMenu(item)">
      <Icon name="bars" />
    </a>
  </div>
</template>

<script>
import gallery from "./gallery";
export default {
  props: ["item", "index"],
  mixins: [gallery],
  methods: {
    loaded: function() {
      if (this.index === 0) {
        this.$emit("loaded");
      }
    },
  },
};
</script>
